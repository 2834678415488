import { useMemo } from 'react'
import { useTerritoriesList } from '@src/data/api/territories-api/territories-api'
import { Territory } from '@src/data/types/Territory'
import { useSelectedCompany } from '@src/contexts/SelectedCompanyProvider'
import { Merchant } from '../types/Merchant'
import { TerritoryFilter } from '../types/Filter'

const useTerritoryDictionaryById = (merchantsList: Merchant[]): [Record<string, string>, boolean] => {
  const selectedCompany = useSelectedCompany()

  const filterQuery: TerritoryFilter = useMemo(() => {
    return {
      financingCompanyId: selectedCompany,
    } as TerritoryFilter
  }, [selectedCompany])

  const [territoriesList, isLoading] = useTerritoriesList(filterQuery)

  return useMemo(() => {
    const territories: Record<string, string> = {}
    if (territoriesList && merchantsList.length) {
      territoriesList.forEach((territory: Territory) => {
        territories[territory.id] = territory.name
      })
    }
    return [territories, isLoading]
  }, [territoriesList, isLoading, merchantsList])
}

export default useTerritoryDictionaryById
