import { Grid, MenuItem, SelectChangeEvent, TextField } from '@mui/material'
import { EFinancingProgram } from '@src/data/types/Constants'
import { debounce } from 'lodash-es'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  handleSearch: (value: string) => void
  financingProgramIds: EFinancingProgram[]
  setFinancingProgramIds: (ids: EFinancingProgram[]) => void
  nameInitValue: string
  availableProgramIds: EFinancingProgram[]
}

const BrowseMerchantToolbar = ({
  handleSearch,
  financingProgramIds,
  setFinancingProgramIds,
  nameInitValue,
  availableProgramIds,
}: Props) => {
  const { t } = useTranslation()
  const debounceOnChange = debounce(handleSearch, 500)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setFinancingProgramIds(value as EFinancingProgram[])
  }

  const getProgramName = useCallback(
    (program: EFinancingProgram) => {
      return t(`common.eFinancingProgram.${program}`)
    },
    [t],
  )

  return (
    <Grid container spacing={2} sx={{ mb: 2, marginLeft: 2, marginTop: 2 }}>
      <Grid item sm={12} xs={6} md={2}>
        <TextField
          label={t('common.search')}
          placeholder={t('common.name')}
          onChange={(e) => debounceOnChange(e.target.value)}
          defaultValue={nameInitValue}
          fullWidth
        />
      </Grid>
      <Grid item sm={12} xs={6} md={2}>
        <TextField
          label={t('common.program')}
          value={financingProgramIds}
          select
          SelectProps={{ multiple: true, onChange: handleChange }}
          fullWidth
        >
          {availableProgramIds.map((program) => (
            <MenuItem key={program} value={program}>
              {getProgramName(program)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default BrowseMerchantToolbar
