import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material'
import useValueList from '@src/data/api/config-api'
import { EValueList } from '@src/data/types/Constants'
import { autoCompleteOnChangeAdapter } from '@src/services/mui-form-adapter'
import { forwardRef, useMemo } from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type MerchantTagsFormValues = {
  tags: string[] | null
}

const MerchantTagsInput = forwardRef(({ onChange, ...props }: ControllerRenderProps, ref) => {
  const { t } = useTranslation()
  const [tags, isFetchingTags] = useValueList(EValueList.Tags)

  const tagsMap = useMemo(() => {
    const map = new Map<string, string>()
    tags?.forEach((item) => map.set(item.id, item.text))
    return map
  }, [tags])

  return (
    <Autocomplete
      {...props}
      ref={ref}
      onChange={autoCompleteOnChangeAdapter(onChange)}
      multiple
      loading={isFetchingTags}
      options={Array.from(tagsMap.keys())}
      getOptionLabel={(opt: string) => tagsMap.get(opt)!}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('merchant.tags')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetchingTags ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(tagValue, getTagsProps) =>
        tagValue.map((option, index) => (
          <Chip {...getTagsProps({ index })} key={option as string} label={tagsMap.get(option as string)} />
        ))
      }
    />
  )
})

const MerchantTagsPanel = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<MerchantTagsFormValues>()

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {t('merchant.associate_tags')}
      </Typography>
      <Controller
        name="tags"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={fieldState.invalid} fullWidth>
            <MerchantTagsInput {...field} />
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  )
}

export default MerchantTagsPanel
