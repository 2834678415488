/* eslint-disable func-names */
import * as yup from 'yup'

yup.addMethod<yup.StringSchema>(yup.string, 'EmptyStringToNull', function () {
  return this.transform((_, value: string | null) => {
    return value === '' ? null : value
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsValidCanadianPhone', function (errorMessage: string) {
  return this.test('test-CellPhone-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[0-9]{3}?[-]?[0-9]{3}?[-]?[0-9]{4}'
    if (value) {
      return new RegExp(exp).test(value) || createError({ path, message: 'common.errors.phone' })
    }
    return true
  })
})

declare module 'yup' {
  interface StringSchema {
    IsValidCanadianPhone(): StringSchema
    EmptyStringToNull(): StringSchema
  }
}

export default yup
