import { PublicClientApplication } from '@azure/msal-browser'
import { IcebergUser } from '@src/data/types/IcebergUser'

import { JWT_LOCAL_STORAGE_KEY, loadUserFromJwtString } from '@src/data/providersUtils/UserProviderUtils'
import { msalConfig } from '../authConfig'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

export function initializeMsal(): PublicClientApplication {
  return msalInstance
}

export function getUser(): IcebergUser | null {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
  return token ? loadUserFromJwtString(token) : null
}
