import { UseAutocompleteProps } from '@mui/material'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'

/** React Hook Form onChange */
type FormControllerOnChange = (...event: unknown[]) => void

/**
 * Mui et React Hook Form ne s'aime vraiment pas entre eux.
 * Mui envoie souvent des Event avec des fausse donnée de value dû à des input complexe qui ne sont pas toujours des inputs.
 * Dans le cas du Autocomplete, l'event qu'envoie Mui possède la valeur de "e.target.value" à 0 (une fausse value).
 * C'est pourquoi Mui fournis un deuxième param qui est la vrai value.
 * Malheureusement, React Hook Form se base seulement sur le premier paramètre qui est faux.
 *
 * @param onChange React Hook Form onChange
 * @returns Mui Autocomplete onChange
 */
export const autoCompleteOnChangeAdapter =
  <
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
  >(
    onChange: FormControllerOnChange,
  ): UseAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>['onChange'] =>
  (_e, value) => {
    onChange(value)
  }

/**
 * Mui et React Hook Form ne s'aime vraiment pas entre eux.
 * L'event OnChange d'une switch ou d'un checkbox ne retourne pas de boolean value.
 * C'est pourquoi Mui fournis un deuxième param qui est la vrai value.
 * Malheureusement, React Hook Form se base seulement sur le premier paramètre qui est faux.
 *
 * @param onChange React Hook Form onChange
 * @returns Mui Autocomplete onChange
 */
export const switchOnChangeAdapter =
  (onChange: FormControllerOnChange): SwitchBaseProps['onChange'] =>
  (_e, value) => {
    onChange(value)
  }
