import { Container } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

const TemplateLogin = () => {
  return (
    <Container maxWidth="xl" sx={{ overflow: 'auto' }}>
      <Outlet />
    </Container>
  )
}

export default TemplateLogin
