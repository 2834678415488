import React, { useCallback } from 'react'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Territory, TerritoryRow } from '@src/data/types/Territory'
import { ESourceSystem } from '@src/data/types/Constants'
import { useTranslation } from 'react-i18next'
import { useDeleteTerritory } from '@src/data/api/territories-api/territories-api'
import IconButtonWithTooltip from '../../../components/IconButtonWithTooltip'

type ActionCellProps = {
  row: TerritoryRow
  handleEdit: (row: TerritoryRow) => void
  setError: React.Dispatch<React.SetStateAction<Error | null>>
}

const ActionCell: React.FC<ActionCellProps> = ({ row, handleEdit, setError }) => {
  const { t } = useTranslation()
  const [deleteTerritory, isDeleting] = useDeleteTerritory()
  const cantEditOrDeleteMonoTerritory = row.sourceSystem === ESourceSystem.monolith.toString()

  const handleDelete = useCallback(async () => {
    try {
      await deleteTerritory({ ...row } as Territory)
    } catch (err) {
      setError(err as Error)
    }
  }, [deleteTerritory, row, setError])

  return (
    <Box position="relative">
      <IconButtonWithTooltip
        onClick={() => handleEdit(row)}
        tooltip={t('common.editTerritory')}
        icon={<EditIcon />}
        disabled={cantEditOrDeleteMonoTerritory}
      />

      <IconButtonWithTooltip
        onClick={() => handleDelete()}
        tooltip={t('common.deleteTerritory')}
        icon={<DeleteIcon />}
        disabled={cantEditOrDeleteMonoTerritory || isDeleting}
      />
    </Box>
  )
}

export default ActionCell
