import { useUpdateMerchant } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/data/types/Merchant'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Stack } from '@mui/material'
import { AsyncActionButton } from '@src/components'
import { EMerchantProperties } from '@src/data/types/Constants'
import { memo } from 'react'
import MerchantPropertiesPanel, { MerchantProperties } from './MerchantPropertiesPanel'
import MerchantTagsPanel, { MerchantTagsFormValues } from './MerchantTagsPanel'
import MerchantTerritory, { MerchantTerritoryFormValues } from './MerchantTerritory'

type Props = {
  merchant: Merchant
}

type MerchantFormValues = MerchantProperties & MerchantTagsFormValues & MerchantTerritoryFormValues

const configProptertyList = Object.values(EMerchantProperties)

const MerchantForm = ({ merchant }: Props) => {
  const { t } = useTranslation()
  const [updateMerchant, isUpdatingMerchant] = useUpdateMerchant()

  const merchantDefaultProperties = configProptertyList.reduce(
    (acc, curr) => {
      acc[curr] = merchant[curr]
      return acc
    },
    {} as Record<EMerchantProperties, boolean>,
  )

  const form = useForm<MerchantFormValues>({
    defaultValues: {
      tags: merchant.tags,
      territoryId: merchant.territoryId,
      ...merchantDefaultProperties,
    },
  })

  const onSubmit = (data: MerchantFormValues) => {
    updateMerchant({
      territoryId: data.territoryId,
      noPaymentPolicy: data.noPaymentPolicy,
      canSkipFlinks: data.canSkipFlinks,
      canPayBorrowers: data.canPayBorrowers,
      automaticIncomeApproval: data.automaticIncomeApproval,
      merchantId: merchant.id,
      tags: data.tags,
    })
      .then(() => form.reset(data))
      .catch(reportErrorToConsole)
  }

  return (
    <FormProvider {...form}>
      <Stack gap={2} component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <MerchantTerritory merchant={merchant} />
        <MerchantTagsPanel />
        <MerchantPropertiesPanel />
        <Stack direction="row" justifyContent="end">
          <AsyncActionButton
            variant="contained"
            color="primary"
            type="submit"
            isPending={isUpdatingMerchant}
            disabled={isUpdatingMerchant || !form.formState.isDirty}
          >
            {t('common.save')}
          </AsyncActionButton>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default memo(MerchantForm)
