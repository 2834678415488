import { omit } from 'lodash-es'
import { IcebergUser, UserRight, UserRights } from '../types/IcebergUser'
import { EFinancingCompany } from '../types/Constants'

export const JWT_LOCAL_STORAGE_KEY = 'jwtToken'

function parseJwt(token: string): object {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload) as object
}

function buildRights(jwtRights: UserRight[]): UserRights {
  const ret = {} as UserRights
  jwtRights.forEach((r: UserRight) => {
    ret[r] = true
  })
  return ret
}

export function saveUserToken(jwtToken: string): void {
  localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwtToken)
}

export function getUserToken(): string {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
  return token || ''
}

export function loadUserFromJwtString(jwtToken: string): IcebergUser {
  const dto = parseJwt(jwtToken) as UserDto
  dto.companyIds ??= ''
  const user: IcebergUser = {
    ...omit(dto, ['roles', 'rights']),
    isInternal: dto.isInternal === 'true',
    roles: dto.roles.split(','),
    rights: buildRights(dto.rights.split(',') as UserRight[]),
    companyIds: dto.companyIds.split(',') as EFinancingCompany[],
  }
  return user
}

export function isExpiredToken(expiryDate: number): boolean {
  const date = new Date().valueOf() / 1000
  return date > expiryDate
}

type UserDto = {
  id: string
  username: string
  name: string
  roles: string
  rights: string
  langId: string
  isInternal: string
  companyIds: string
  exp: number /* expire date in ms. to get the date, do new Date(user.exp * 1000) */
}
