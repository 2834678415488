import { Button, ButtonProps, LinearProgress, Stack, SxProps, Theme } from '@mui/material'

interface Props extends ButtonProps {
  isPending?: boolean
  children?: React.ReactNode
  isRounded?: boolean
  sx?: SxProps<Theme>
}

const AsyncActionButton = ({ isPending, disabled, isRounded, children, sx, ...otherProps }: Props): JSX.Element => {
  return (
    <Button
      type="button"
      {...otherProps}
      disabled={isPending || disabled}
      sx={{
        ...sx,
        borderRadius: isRounded ? '1rem' : undefined,
      }}
    >
      <Stack>
        {isPending && <LinearProgress />}
        {children}
      </Stack>
    </Button>
  )
}

export default AsyncActionButton
