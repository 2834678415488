import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { InputTextField } from '@src/components'
import { debounce } from 'lodash-es'
import { Territory } from '@src/data/types/Territory'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerritoriesList } from '@src/data/api/territories-api/territories-api'
import { ESourceSystem } from '@src/data/types/Constants'
import { useSelectedCompany } from '@src/contexts/SelectedCompanyProvider'
import { TerritoryFilter } from '@src/data/types/Filter'

type OnSelectSingleTerritory = (territory: Territory) => void

type Props = {
  open: boolean
  title: string
  label: string
  onConfirm: OnSelectSingleTerritory
  onCancel: () => void
  sourceSystem: ESourceSystem
}

const SelectTerritoryDialog = ({ onConfirm, onCancel, open, title, label, sourceSystem }: Props) => {
  const TERRITORIES_LIMIT = 25
  const { t } = useTranslation()

  const [selectedTerritory, setSelectedTerritory] = useState<Territory | null>(null)
  const selectedCompany = useSelectedCompany()
  const [query, setQuery] = useState<string>('')

  const cancel = () => {
    setQuery('')
    setSelectedTerritory(null)
    onCancel()
  }

  const confirmSelection = useCallback(() => {
    if (selectedTerritory) {
      onConfirm(selectedTerritory)
      setQuery('')
      setSelectedTerritory(null)
    }
  }, [onConfirm, selectedTerritory])

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const debounceOnChange = debounce(updateQuery, 1000)

  React.useEffect(() => {
    return () => {
      debounceOnChange.cancel()
    }
  }, [debounceOnChange])

  const filterQuery: TerritoryFilter = useMemo(() => {
    return {
      financingCompanyId: selectedCompany,
    } as TerritoryFilter
  }, [selectedCompany])

  const [territoriesList, isFetchingTerritoriesList] = useTerritoriesList(filterQuery)

  const filteredTerritories = useMemo(
    () =>
      territoriesList.filter(
        (territory) =>
          territory.name.toLowerCase().includes(query.toLowerCase()) &&
          territory.sourceSystem === sourceSystem.toString(),
      ),
    [query, sourceSystem, territoriesList],
  )

  const handleItemSelected = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const itemValue = (evt as React.MouseEvent<HTMLButtonElement>).currentTarget.id
      const value = territoriesList.find((x) => x.id === itemValue)
      if (value) {
        setSelectedTerritory(value)
      }
    },
    [territoriesList],
  )

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid', textAlign: 'center' }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid item container spacing={2} sx={{ mt: 5, width: 600 }}>
          <Grid item xs={12} md={14}>
            <InputTextField id="queryField" onChange={debounceOnChange} label={label} />
          </Grid>
          <Grid item xs={12} md={14}>
            {isFetchingTerritoriesList ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {query && query.trim() && territoriesList.length === 0 && (
                  <Alert severity="info">{t('common.searchYieldsNoResults')}</Alert>
                )}
                {query && territoriesList.length >= TERRITORIES_LIMIT && (
                  <Alert severity="info">{t('common.tooManyResultsMessage', { limit: TERRITORIES_LIMIT })}</Alert>
                )}
                {(!query || !query.trim()) && <p>{t('common.typeToStartSearch')}</p>}
                {query.length > 0 && (
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 800,
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      height: 300,
                      '& ul': { padding: 0 },
                    }}
                  >
                    {filteredTerritories.map((territory) => (
                      <ListItemButton
                        id={territory.id}
                        key={territory.id}
                        selected={selectedTerritory?.id === territory.id}
                        onClick={handleItemSelected}
                      >
                        <ListItemText>{territory.name}</ListItemText>
                      </ListItemButton>
                    ))}
                  </List>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={cancel}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" color="success" startIcon={<DoneIcon />} onClick={confirmSelection}>
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(SelectTerritoryDialog)
