import { Navigate, Route, Routes } from 'react-router-dom'
import AuthPage from '../Authentication'
import Home from '../Home/HomePage'
import LoginPage from '../Home/LoginPage'
import ViewMerchant from '../ViewMerchant'
import BannerMerchant from '../ViewBanner'
import Territories from '../BrowseTerritories'
import GuestGuard from './components/GuestGuard'
import TemplateApp from './components/TemplateApp'
import TemplateLogin from './components/TemplateLogin'
import SelectedCompanyProvider from '../../contexts/SelectedCompanyProvider'
import AuthGuard from './components/AuthGuard'
import EditMerchant from '../EditMerchant'

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route element={<SelectedCompanyProvider />}>
          <Route element={<TemplateApp />}>
            <Route path="/" element={<Navigate to="/merchant/browse" replace />} />
            <Route path="/merchant/browse" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="/territories" element={<Territories />} />
            <Route path="/merchant/:id">
              <Route path="view" element={<ViewMerchant />} />
              <Route path="edit" element={<EditMerchant />} />
            </Route>
            <Route path="/banner/:id/view" element={<BannerMerchant />} />
          </Route>
        </Route>
      </Route>
      <Route element={<GuestGuard />}>
        <Route element={<TemplateLogin />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Route>
      <Route path="auth-page" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default TopLevelRoutes
