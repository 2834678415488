import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EFinancingCompany } from '@src/data/types/Constants'
import MerchantForm from './components/MerchantForm'

const EditMerchantPage = () => {
  const { id } = useParams()
  const [merchant, isLoading] = useMerchantById(id ?? '')
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }
  if (!merchant) {
    return <div>merchant not found</div>
  }

  if (merchant?.financingCompanyId === EFinancingCompany.Iceberg) navigate(`/merchant/${id}/view`, { replace: true })

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          {t('merchant.edit')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {merchant.name}
        </Typography>
      </Box>
      <MerchantForm merchant={merchant} />
    </Box>
  )
}

export default EditMerchantPage
