import { Tooltip, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AddTerritoryDialog from '@src/containers/BrowseTerritories/components/AddTerritoryDialog'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

type Props = {
  disableButton: boolean
}

const MainActions = ({ disableButton }: Props) => {
  const { t } = useTranslation()
  const [openAddTerritoryDialog, setOpenAddTerritoryDialog] = useState(false)

  const handleAdd = () => {
    setOpenAddTerritoryDialog(true)
  }

  const handleCloseProfile = () => {
    setOpenAddTerritoryDialog(false)
  }

  return (
    <Stack direction="row">
      <Tooltip title={t('common.addTerritory')}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            style={{ marginBottom: '20px' }}
            disabled={disableButton}
          >
            <AddCircleOutlineRoundedIcon />
          </Button>
        </span>
      </Tooltip>
      <AddTerritoryDialog
        open={openAddTerritoryDialog}
        handleClose={handleCloseProfile}
        title={t('common.newTerritory')}
      />
    </Stack>
  )
}

export default MainActions
