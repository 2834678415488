import { useUser } from '@src/contexts/AppContext'
import { EFinancingCompany } from '@src/data/types/Constants'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'

type TSelectedCompanyContext = {
  selectedCompany: EFinancingCompany | null
  setSelectedCompany: React.Dispatch<React.SetStateAction<EFinancingCompany | null>>
}
const SelectedCompanyContext = createContext<TSelectedCompanyContext | null>(null)

const SelectedCompanyProvider = () => {
  const user = useUser()
  const userCompany = user?.companyIds ? user.companyIds[0] : null
  const [selectedCompany, setSelectedCompany] = useState<EFinancingCompany | null>(userCompany)
  const value = useMemo<TSelectedCompanyContext>(
    () => ({
      selectedCompany,
      setSelectedCompany,
    }),
    [selectedCompany],
  )

  return (
    <SelectedCompanyContext.Provider value={value}>
      <Outlet />
    </SelectedCompanyContext.Provider>
  )
}

export const useSelectedCompany = () => {
  const ctx = useContext(SelectedCompanyContext)
  return ctx?.selectedCompany as EFinancingCompany
}

export const useSetSelectedCompany = () => {
  const ctx = useContext(SelectedCompanyContext)
  return ctx?.setSelectedCompany as React.Dispatch<React.SetStateAction<EFinancingCompany | null>>
}

export default SelectedCompanyProvider
