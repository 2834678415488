import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { AsyncActionButton, InputTextFieldWithMask } from '@src/components'
import { useCreateUser, useResetPassword, useUpdateUser } from '@src/data/api/user-api/user-api'
import { MerchantDashboardUser, MerchantDashboardUserSchema } from '@src/data/types/MerchantDashboardUserSchema'
import translate from '@src/services/translate'
import { AxiosError } from 'axios'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: (user?: MerchantDashboardUser) => void
  user?: MerchantDashboardUser
  merchantId: string
}

const EditProfileDialog = ({ open, handleClose, user, merchantId }: Props) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<MerchantDashboardUser>({
    mode: 'onBlur',
    defaultValues: { ...user, merchantId },
    resolver: yupResolver(MerchantDashboardUserSchema),
  })

  React.useEffect(() => {
    reset(user)
  }, [open, reset, user])

  const [editUser, isEditingUser, resetEditUser] = useUpdateUser()
  const [createUser, isCreatingUser, resetCreateUser, createUserError] = useCreateUser()
  const [resetPassword, isResetingPassword, resetResetPassword] = useResetPassword()

  const onSubmit = async (data: MerchantDashboardUser) => {
    if (user)
      await editUser({ ...user, ...data }).then((value) => {
        resetEditUser()
        if (value) handleClose(value)
      })
    else
      await createUser(data).then((value) => {
        resetCreateUser()
        if (value) handleClose(value)
      })
  }

  const handleResetPassword = async () => {
    if (user)
      await resetPassword(user.email).then((value) => {
        resetResetPassword()
        if (value) handleClose()
      })
  }

  const isLoading = isEditingUser || isResetingPassword || isCreatingUser

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      transitionDuration={0}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {user ? t('profile.edit') : t('profile.addUser')}
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
          }}
          style={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {createUserError !== null && (
          <Alert sx={{ mt: 1 }} severity="error">
            {(createUserError as AxiosError<string>).response?.data}
          </Alert>
        )}

        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <TextField
              label={t('common.firstName')}
              fullWidth
              {...register('firstName')}
              error={!!errors.firstName}
              helperText={errors?.firstName?.message ? translate(String(errors.firstName.message)) : ''}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label={t('common.lastName')}
              fullWidth
              {...register('lastName')}
              error={!!errors.lastName}
              helperText={errors?.lastName?.message ? translate(String(errors.lastName.message)) : ''}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id={t('common.language')}
              select
              label={t('common.language')}
              defaultValue={user?.language ?? 'en'}
              {...register('language')}
              error={!!errors.language}
              helperText={errors?.language?.message ? translate(String(errors.language.message)) : ''}
              fullWidth
            >
              <MenuItem value="en">{t('common.languageId.1')}</MenuItem>
              <MenuItem value="fr">{t('common.languageId.0')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('common.jobTitle')}
              fullWidth
              {...register('jobTitle')}
              error={!!errors.jobTitle}
              helperText={errors?.jobTitle?.message ? translate(String(errors.jobTitle.message)) : ''}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputTextFieldWithMask
                  mask="000-000-0000"
                  error={errors?.phone}
                  inputProps={{ inputMode: 'tel' }}
                  helperText={errors?.phone?.message ? translate(String(errors.phone.message)) : ''}
                  label={t('common.phone')}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('common.email')}
              fullWidth
              {...register('email')}
              error={!!errors.email}
              disabled={!!user}
              helperText={errors?.email?.message ? translate(String(errors.email.message)) : ''}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" justifyContent="center" spacing={5}>
              <FormControlLabel
                {...register('isAdmin')}
                control={<Checkbox id="isAdmin" checked={watch('isAdmin')} />}
                label={t('profile.admin')}
                labelPlacement="end"
              />
              <FormControlLabel
                {...register('accountEnabled')}
                control={<Checkbox id="accountEnabled" checked={watch('accountEnabled')} />}
                label={t('profile.active')}
                labelPlacement="end"
                disabled={!user}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={5}>
              <AsyncActionButton
                variant="outlined"
                onClick={handleResetPassword}
                isPending={isResetingPassword}
                disabled={isLoading || user === undefined}
              >
                {t('profile.resetPassword')}
              </AsyncActionButton>
              <AsyncActionButton
                variant="outlined"
                isPending={isEditingUser || isCreatingUser}
                disabled={isLoading}
                onClick={async () => {
                  await handleSubmit(onSubmit)()
                }}
              >
                {user ? t('profile.save') : t('profile.addUser')}
              </AsyncActionButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(EditProfileDialog)
