import { EFinancingCompany, EFinancingProgram } from '@src/data/types/Constants'
import { useMemo } from 'react'

const useSelectedCompanyPrograms = (selectedCompany: EFinancingCompany) => {
  return useMemo(() => {
    if (selectedCompany === EFinancingCompany.IFinance) {
      return [EFinancingProgram.Personal]
    }

    return [EFinancingProgram.Auto, EFinancingProgram.IfXpress, EFinancingProgram.Products]
  }, [selectedCompany])
}

export default useSelectedCompanyPrograms
