import React from 'react'
import { Chip, IconButton, Stack, Typography } from '@mui/material'
import { Address } from '@src/data/types/Address'
import { Merchant } from '@src/data/types/Merchant'
import { formatAddress } from '@src/services/strings'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { EFinancingCompany, EValueList } from '@src/data/types/Constants'
import useValueList from '@src/data/api/config-api'

type Props = {
  merchant: Merchant
}

const MerchantInformation = ({ merchant }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tags, isLoading] = useValueList(EValueList.Tags)

  const handleEditClick = () => {
    navigate(`/merchant/${merchant.id}/edit`)
  }
  const address: Address = {
    stateIso: merchant?.province,
    city: merchant?.city,
    country: merchant?.country,
    postalCode: merchant?.zipCode,
    street: merchant?.address,
  } as Address

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} padding={2}>
      {merchant && !isLoading && (
        <>
          <Stack>
            <Typography variant="h6" component="div">
              {merchant.name}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {formatAddress(address)}
            </Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {merchant.tags?.map((tag) => <Chip key={tag} label={tags?.find((item) => item.id === tag)?.text} />)}
            </Stack>
          </Stack>

          <Typography
            variant="body1"
            component="div"
            sx={{ border: '2px solid', borderRadius: 2, p: 1, mb: 1, borderColor: 'error.main', float: 'right' }}
          >
            {t(`common.eLanguageAbbreviation.${merchant.languageId}`)} | {merchant.province}
          </Typography>
          <IconButton
            id="btnEditMerchant"
            aria-label="edit-merchant"
            color="primary"
            onClick={handleEditClick}
            disabled={merchant.financingCompanyId === EFinancingCompany.Iceberg}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
    </Stack>
  )
}

export default MerchantInformation
