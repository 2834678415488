import { SelectChangeEvent, Select, MenuItem } from '@mui/material'
import { useUser } from '@src/contexts/AppContext'
import { EFinancingCompany } from '@src/data/types/Constants'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelectedCompany, useSetSelectedCompany } from '../../../contexts/SelectedCompanyProvider'

const CompanySelector = () => {
  const user = useUser()
  const setSelectedCompany = useSetSelectedCompany()
  const selectedCompany = useSelectedCompany()
  const availableCompanies = Array.isArray(user?.companyIds) ? user?.companyIds : []
  const navigate = useNavigate()
  const location = useLocation()
  const updateSearch = new URLSearchParams(location.search)

  const showCompanySelector =
    availableCompanies.includes(EFinancingCompany.IFinance) && availableCompanies.includes(EFinancingCompany.Iceberg)

  const handleChange = (event: SelectChangeEvent<EFinancingCompany>) => {
    const company = event.target.value as EFinancingCompany
    setSelectedCompany(company)
    updateSearch.set('financingCompanyId', company)
    updateSearch.delete('financingProgramIds')

    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  if (!showCompanySelector || availableCompanies.length === 0) {
    return null
  }

  return (
    <Select value={selectedCompany} onChange={handleChange} sx={{ minWidth: 90 }} size="small">
      {availableCompanies.map((company) => (
        <MenuItem key={company} value={company}>
          {company}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CompanySelector
