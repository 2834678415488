import { Grid, Tooltip, Button, Link, Typography, IconButton } from '@mui/material'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import AddBannerDialog from '@src/containers/BrowseMerchant/components/AddBannerDialog'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Merchant } from '@src/data/types/Merchant'
import { EFinancingCompany, ELanguage, EServiceCategory } from '@src/data/types/Constants'
import LanguageIcon from '@mui/icons-material/Language'
import { useSelectedCompany } from '@src/contexts/SelectedCompanyProvider'

type Props = {
  bannersList: Merchant[]
}

const BannersList = ({ bannersList }: Props) => {
  const { t } = useTranslation()
  const [openBannerDialog, setOpenBannerDialog] = useState(false)
  const selectedCompany = useSelectedCompany()
  const handleCloseProfile = () => {
    setOpenBannerDialog(false)
  }

  const handleAdd = () => {
    setOpenBannerDialog(true)
  }

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: t('common.name'),
        flex: 2,
        renderCell: (params: GridRenderCellParams<Merchant, string>) => {
          return <Link href={`/banner/${params.row.id}/view`}>{params.value?.split('*').join(' ')}</Link>
        },
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 2,
      },
      {
        field: 'serviceCategory',
        headerName: t('common.serviceCategory'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Merchant, EServiceCategory>) => {
          return <Typography>{t(`eServiceCategory.${params.value!}`)}</Typography>
        },
      },
      {
        field: 'phone',
        headerName: t('common.phone'),
        flex: 1,
      },
      {
        field: 'languageId',
        headerName: t('common.language'),
        flex: 1,
        cellClassName: 'normalLineHeight',
        renderCell: (params: GridRenderCellParams<Merchant, ELanguage>) => {
          return <Typography>{t(`common.languageId.${params.value!}`)}</Typography>
        },
      },
      {
        field: 'province',
        headerName: 'Province',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'website',
        headerName: t('common.website'),
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<Merchant, string>) => {
          return (
            <IconButton disabled={!params.value}>
              <Link href={params.value} target="_blank">
                <LanguageIcon color={!params.value ? 'disabled' : 'inherit'} />
              </Link>
            </IconButton>
          )
        },
      },
    ],
    [t],
  )

  return (
    <div>
      <Grid item sm={12} xs={6} md={12} container justifyContent="left">
        <Tooltip title={t('common.addBanner')}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            style={{ marginBottom: '20px' }}
            disabled={selectedCompany === EFinancingCompany.Iceberg}
          >
            <AddCircleOutlineRoundedIcon />
          </Button>
        </Tooltip>
      </Grid>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        disableColumnFilter
        paginationMode="server"
        rowCount={bannersList.length}
        disableRowSelectionOnClick
        rows={bannersList}
        columns={columns}
      />
      <AddBannerDialog open={openBannerDialog} handleClose={handleCloseProfile} title={t('common.newBanner')} />
    </div>
  )
}

export default BannersList
