import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ValueListDto, ValueListItem, ValueListItemDto } from '../types/ValueListDto'
import { EValueList } from '../types/Constants'
import { getApiClient } from './api-client'

function buildValueList(list: ValueListDto | null, lang: string) {
  if (!list) return []

  const inputList: ValueListItemDto[] = [...list.items]
  if (list.sortMethod === 'priority') inputList.sort((x) => x.priority ?? 0)

  const items: ValueListItem[] = inputList.map((x) => {
    return {
      id: x.value,
      text: lang === 'fr' ? x.textFr : x.textEn,
    }
  })
  if (list.sortMethod === 'alpha') items.sort((one, two) => (one.text > two.text ? -1 : 1))
  return items
}

const getValueList = async (configId: EValueList) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<ValueListDto>(`/Configs/${configId}`, {})
  return response.data
}

export default function useValueList(configId: EValueList): [ValueListItem[] | undefined, boolean] {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { data, isFetching } = useQuery({
    queryKey: ['config', configId],
    queryFn: () => getValueList(configId),
  })

  const formatedData = useMemo<ValueListItem[]>(
    () => (data ? buildValueList(data, resolvedLanguage!) : []),
    [data, resolvedLanguage],
  )

  return [formatedData, isFetching]
}
