import React, { forwardRef, useMemo, useState } from 'react'

import { BrowserRouter as Router, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { CssBaseline, LinkProps, ThemeProvider, createTheme } from '@mui/material'
import { blue } from '@mui/material/colors'
import { interceptError } from '@src/services/http-common'
import { AxiosError } from 'axios'
import ErrorFallback from '@src/components/ErrorFallback'
import { ColorModeContext } from '@src/contexts/ColorModeContext'
import UserProvider from '@src/contexts/UserProvider'
import TopLevelRoutes from './Routes'

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)

const PREFERS_DARK_SETTING = 'darkMode'

const App = () => {
  const [error, setError] = React.useState<AxiosError | Error | null>(null)
  const prevMode = localStorage.getItem(PREFERS_DARK_SETTING)
  const [mode, setMode] = useState<'light' | 'dark'>((prevMode as 'light' | 'dark') || 'light')

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => {
          const newMode = previousMode === 'light' ? 'dark' : 'light'
          localStorage.setItem(PREFERS_DARK_SETTING, newMode)
          return newMode
        })
      },
      error,
    }),
    [error],
  )

  interceptError(setError)

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiInputBase: {
            defaultProps: {
              // Needed to prevent adding a global style for every input field
              disableInjectingGlobalStyles: true,
            },
          },
        },
        palette: {
          primary: { main: mode === 'dark' ? blue[200] : blue[700] },
          background: {
            default: mode === 'dark' ? '#1e1e1e' : '#ffffff',
          },
          mode,
        },
      }),
    [mode],
  )
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <ColorModeContext.Provider value={colorMode}>
          {error && <ErrorFallback error={error} resetError={setError} />}
          <CssBaseline />
          <Router>
            <TopLevelRoutes />
          </Router>
        </ColorModeContext.Provider>
      </UserProvider>
    </ThemeProvider>
  )
}

export default App
